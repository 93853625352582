import React from 'react';
import Img, {GatsbyImageProps} from 'gatsby-image';
import {ImageSharp} from '../common/types';

type PreviewCompatibleImageProps = {
	imageInfo: {
		alt?: string
		childImageSharp?: GatsbyImageProps
		image?: ImageSharp | string
		style?: object
	},
	className?: string
}

const PreviewCompatibleImage: React.FC<PreviewCompatibleImageProps> = ({className, imageInfo}) => {
	const { alt = '', childImageSharp, image, style } = imageInfo;
	const { publicURL, extension } = image;
	// svg support
	if (!childImageSharp && extension === 'svg') {
		return <img className={className} style={style} src={publicURL} alt={alt} />
	}
	if (!!image && typeof image !== 'string') {
		return <Img className={className} style={style} fluid={image.childImageSharp.fluid} alt={alt} />;
	}
	if (!!childImageSharp) {
		return <Img className={className} style={style} fluid={childImageSharp.fluid} alt={alt} />;
	}
	if (!!image && typeof image === 'string')
		return <img className={className} style={style} src={image} alt={alt} />;
	return null;
};

export default PreviewCompatibleImage;
